.image-container{
     
    align-items: left; 
}
.icon-image{
    width:30px;
    height:30px;
     /* margin-left:8px  */
}  
.list-item { 
     margin-left: 10px; 
     align-items: 'center';
   } 
.list-item-text{
    color:#914572;
    padding-bottom:20px ;
}
 img {
    width: 45px; 
    height:45px;
    margin-right: 8px; 
  } 
.import-lead-button {
    height:30px;
    width:30px;
  }
  .dropdown-icon{
    background-repeat: no-repeat;
  background-position: right 13px center;
  background-size: 16px;
  background-image: url('./assests/icons/down.png');
  }  
  .action-buttons {
    display: flex;
    gap: 3px;
  }
  
  .action-button {
    padding: 0;
    border: none;
    cursor: pointer;
  }
  
  .action-button-icon {
    width: 15px; /* Adjust the width */
    height: 15px; /* Adjust the height  */
    cursor: pointer;
  }
  .plus-button-icon{

    display: flex;
  
    width: 20px; /* Adjust the width */
  
    height: 20px; /* Adjust the height  */
    cursor: pointer;
  
   }
  .custom-datepicker {
    background-repeat: no-repeat;
    background-position: right 13px center;
    background-size: 30px;
    background-image: url('./assests/icons/calendar.png');
    border: 2px solid #ccc; /* Set the desired border styles */
    border-radius: 5px; /* Set the desired border radius */
    font-size: 14px; /* Set the desired font size */
    height: 2.7rem;
    width: 19.6rem;
    margin-left: 0.3rem;
    border-radius: 5px;
    background-color: rgb(234, 237, 240,1);
    cursor: pointer;
  }
  /* Hide the clear button ('x' icon) */
.react-date-picker__clear-button {
  display: none;
}

/* Hide the calendar input icon */
.react-date-picker__calendar-button {
  display: none;
}
.file-icon {
  width: 30px; /* Adjust the width */
  height: 30px; /* Adjust the height  */
  cursor: pointer;
}

/* Add these styles to your CSS file or include them in your React component */
.table {
  border-collapse: collapse;
  width: 100%;
 
}

.table th, .table td {
  border: 1px solid #ddd; /* Add borders to cells */
  padding: 8px; /* Add padding to cells */
  text-align: left;
 

}

.table th {
  background-color: #f2f2f2; /* Add a background color to header cells */
}

.table thead th {
  border-bottom: 2px solid #ddd; /* Add a bottom border to header cells */


}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9; /* Add a background color to even rows */
}

.table tbody tr:hover {
  background-color: #f0f0f0; /* Change background color on hover */
}
